import React from 'react'
import { CircularProgress, styled, Typography } from '@mui/material'

const LoadingSpinner = styled(CircularProgress)`
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: -5px;
`

const Title = styled(Typography)`
  margin-left: auto;
  margin-right: auto;
  max-width: 950px;
`

export const TitleAndText: React.FC<{
  title?: string
  text?: string
  isLoading?: boolean
}> = ({ title, text, isLoading, children }) => (
  <>
    {title && (
      <Title variant="h2" align="center" margin={2}>
        {title}
      </Title>
    )}
    <Typography variant="body1" align="center" margin={4} mb={5}>
      {isLoading && <LoadingSpinner size={30} />}
      {text}
      {children}
    </Typography>
  </>
)

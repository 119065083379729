import ClientOAuth2 from 'client-oauth2'

type LoginState = {
  type: 'Invalid' | 'Guest' | 'LoggedIn'
  accessToken?: string
}
// | { type: 'Invalid' }
// | { type: 'Guest' }
// | { type: "LoggedIn", accessToken: string }

const stateKey = '@aifa-whitelist-boxset:auth:state'

export const login = () => {
  // create state variable which gets verified on redirect to avoid CSRF and ClickJacking
  const state = `${Math.floor(Math.random() * 10 ** 16)}`

  // store state in local storage to check against when the user gets redirected back.
  window.localStorage.setItem(stateKey, state)

  const authenticator = new ClientOAuth2({
    clientId: process.env.REACT_APP_DISCORD_AUTH_CLIENT_ID,
    authorizationUri: 'https://discord.com/oauth2/authorize',
    redirectUri: process.env.REACT_APP_DISCORD_AUTH_REDIRECT_URL,
    scopes: ['identify', 'email', 'guilds'],
    state: state,
  })

  window.location.href = authenticator.token.getUri()
}

export const useOAuth = (): LoginState => {
  // The redirect url's hash is a set url encoded key-value pairs
  const params = new URLSearchParams(window.location.hash.slice(0))
  const state = params.get('state')
  const accessToken = params.get('access_token')

  if (!state || !accessToken) {
    return { type: 'Guest' }
  }

  if (state !== window.localStorage.getItem(stateKey)) {
    return { type: 'Invalid' }
  }

  return { type: 'LoggedIn', accessToken }
}

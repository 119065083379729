import { createTheme, PaletteOptions } from '@mui/material/styles'

import {
  gilroy,
  gilroyBold,
  gilroyThin,
  gilroyLight,
  gilroyItalic,
  gilroyMedium,
  gilroyRegular,
  monumentExtended,
  monumentExtendedBold,
  monumentExtendedLight,
  monumentExtendedRegular,
  monumentExtendedUltralight,
} from '../styles/fonts'

const gilroyFontFamily = `${gilroy}, Roboto, Helvetica, Arial, sans-serif`
const monumentExtendedFontFamily = `${monumentExtended}, Roboto, Helvetica, Arial, sans-serif`

const fontWeightLight = 300
const fontWeightRegular = 400
const fontWeightMedium = 500
const fontWeightBold = 700

export const palette: PaletteOptions = {
  primary: {
    main: '#A82B73',
    dark: '#641945',
    light: '#A07AD3',
    contrastText: '#fff',
  },
  secondary: {
    main: '#C0EFFC',
    dark: '#227991',
    light: '#ADE2EB',
    contrastText: '#fff',
  },
  gradients: {
    teal: 'linear-gradient(180deg, #37869B 0%, #B6E9F1 100%)',
    dark: 'linear-gradient(180deg, #1A1B29 0%, #27283A 100%)',
    moody: 'linear-gradient(180deg, #1A1B29 0%, #42435A 100%)',
  },
  background: {
    paper: '#fff',
    default: '#fff',
    // @ts-ignore
    teal: '#227991',
    dark: '#227991',
    light: '#ADE2EB',
    darkTeal: '#105365',
    xlight: 'rgba(192, 239, 252, 0.5)',
  },
  disabled: {
    main: '#949494',
    light: '#f4f4f4',
  },
  success: {
    main: '#4CAF50',
    light: '#CFFFD2',
  },
  warning: {
    main: '#FF9800',
    light: '#fffaea',
  },
  error: {
    main: '#F44336',
    light: '#FBE3EE',
  },
  text: {
    primary: '#1B1C2A',
    secondary: '#fff',
    // @ts-ignore
    mid: '#42435A',
  },
  divider: '#dcdcdc',
  tooltip: {
    main: '#0b1f31',
    secondary: '#FFFFFF',
  },
  toast: {
    error: '#D2003C',
    success: '#84D584',
  },
}

const theme = createTheme({
  palette,
  typography: {
    fontWeightLight,
    fontWeightRegular,
    fontWeightMedium,
    fontWeightBold,
  },
})

theme.shadows[1] = '0px 0px 20px rgba(0, 0, 0, 0.15)'

// We can only use typography function such as pxToRem after the createMuiTheme
theme.typography = {
  ...theme.typography,
  fontWeightBold,
  fontWeightLight,
  fontWeightMedium,
  fontWeightRegular,
  fontFamily: gilroyFontFamily,
  // @ts-ignore
  fontFamilySecondary: monumentExtendedFontFamily,
  h1: {
    ...theme.typography.h1,
    fontFamily: monumentExtendedFontFamily,
    fontWeight: fontWeightBold,
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(80),
    lineHeight: theme.typography.pxToRem(80),
    letterSpacing: theme.typography.pxToRem(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(48),
      lineHeight: theme.typography.pxToRem(48),
    },
  },
  h2: {
    ...theme.typography.h2,
    fontFamily: monumentExtendedFontFamily,
    fontWeight: fontWeightBold,
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(56),
    lineHeight: theme.typography.pxToRem(56),
    letterSpacing: theme.typography.pxToRem(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(40),
      lineHeight: theme.typography.pxToRem(40),
    },
  },
  h3: {
    ...theme.typography.h3,
    fontFamily: monumentExtendedFontFamily,
    fontWeight: fontWeightBold,
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(32),
    lineHeight: theme.typography.pxToRem(32),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(22),
      lineHeight: theme.typography.pxToRem(22),
    },
  },
  h4: {
    ...theme.typography.h4,
    fontWeight: fontWeightBold,
    textTransform: 'uppercase',
    fontFamily: monumentExtendedFontFamily,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(22),
  },
  h5: {
    ...theme.typography.h5,
    fontFamily: monumentExtendedFontFamily,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
  },
  h6: {
    ...theme.typography.h6,
    fontFamily: monumentExtendedFontFamily,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: theme.typography.pxToRem(16),
  },
  subtitle1: {
    ...theme.typography.subtitle1,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  subtitle2: {
    ...theme.typography.subtitle2,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
    fontWeight: fontWeightBold,
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  body1: {
    ...theme.typography.body1,
    fontFamily: gilroyFontFamily,
    fontWeight: fontWeightRegular,
    fontSize: theme.typography.pxToRem(24),
    lineHeight: theme.typography.pxToRem(32),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(18),
      lineHeight: theme.typography.pxToRem(24),
    },
  },
  body2: {
    ...theme.typography.body2,
    fontFamily: gilroyFontFamily,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(18),
    lineHeight: theme.typography.pxToRem(24),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(22),
    },
  },
  button: {
    ...theme.typography.button,
    fontFamily: monumentExtendedFontFamily,
    textTransform: 'uppercase',
    fontWeight: fontWeightBold,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(22),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
      lineHeight: theme.typography.pxToRem(14),
    },
  },
  caption: {
    ...theme.typography.caption,
    fontFamily: gilroyFontFamily,
    fontWeight: fontWeightMedium,
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(18),
  },
  overline: {
    ...theme.typography.overline,
  },
}

// Overrides are assigned here so we can use theme.breakpoints
// Defaults can be deleted. These are used to test that Storybook Mui theming works
theme.components = {
  MuiCssBaseline: {
    styleOverrides: [
      gilroyBold,
      gilroyThin,
      gilroyLight,
      gilroyItalic,
      gilroyMedium,
      gilroyRegular,
      monumentExtendedBold,
      monumentExtendedLight,
      monumentExtendedRegular,
      monumentExtendedUltralight,
    ].join('\n'),
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        borderRadius: '4px',
        letterSpacing: '2px',
        transform: 'skew(-12deg, 0)',
        // !important used so we get consistent padding styles between button variants
        padding: `${theme.spacing(2, 2.5)} !important`,
      },
      outlined: {
        color: '#fff',
        borderColor: '#fff',
        borderWidth: '2px !important',
      },
      startIcon: {
        marginRight: 12,
        transform: 'skew(12deg, 0)',
      },
      endIcon: {
        transform: 'skew(12deg, 0)',
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      root: {
        padding: 4,
        '&:hover': {
          backgroundColor: 'rgba(0,0,0,0.25)',
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      paper: {
        borderRadius: 40,
        backgroundColor: theme.palette.secondary.main,
        [theme.breakpoints.down('sm')]: {
          margin: theme.spacing(2),
        },
      },
      paperWidthSm: {
        width: '100%',
        maxWidth: 1000,
      },
    },
  },
  MuiDialogTitle: {
    styleOverrides: {
      root: {
        textAlign: 'center',
        padding: theme.spacing(4, 4, 2),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(7, 4, 2),
        },
      },
    },
  },
  MuiDialogContent: {
    styleOverrides: {
      root: {
        padding: theme.spacing(0, 4, 4),

        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(0, 2, 4),
        },
      },
    },
  },
  MuiList: {
    styleOverrides: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  MuiMenu: {
    defaultProps: {
      elevation: 1,
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      transformOrigin: { vertical: 'top', horizontal: 'left' },
    },
    styleOverrides: {
      paper: {
        marginTop: 6,
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        paddingTop: 20,
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 20,
        textTransform: 'uppercase',
        fontWeight: fontWeightBold,
        fontFamily: monumentExtendedFontFamily,
        fontSize: theme.typography.pxToRem(18),
        lineHeight: theme.typography.pxToRem(22),
        borderBottom: `1px solid ${theme.palette.divider}`,
        '&:last-child': {
          borderBottom: 'none',
        },
      },
    },
  },
  MuiPopover: {
    defaultProps: {
      elevation: 1,
      anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
      transformOrigin: { vertical: 'top', horizontal: 'left' },
    },
  },
  MuiSnackbar: {
    styleOverrides: {
      root: {
        width: 1280,
        maxWidth: '96%',
        [theme.breakpoints.down('sm')]: {
          maxWidth: '98%',
        },
      },
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        background: 'transparent',
      },
    },
  },
}

export default theme

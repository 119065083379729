import { useState } from 'react'

export interface ToastData {
  message: string
  severity: 'error' | 'success'
}

export interface ToastType extends ToastData {
  open: boolean
}

export type ToastMethodType = (data: ToastData) => void

export const useToast = () => {
  const [toast, setToast] = useState<ToastType>({
    open: false,
    message: '',
    severity: 'error',
  })

  return {
    toast,
    openToast: (data: ToastData) => {
      setToast({ ...data, open: true })
    },
    closeToast: (data: ToastData) => {
      setToast({ ...data, open: false })
    },
  }
}

import React from 'react'
import { useToast } from '../hooks/useToast'
import Toast from './feedback/Toast'
import ScreenSelector from './ScreenSelector'
import { Container } from '@mui/material'
import { css } from '@emotion/react'
import styled from '@emotion/styled'

const Background = styled.div(
  ({ theme }) => css`
    background: ${theme.palette.gradients.teal};
    flex-direction: column;
    width: 100vw;
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 100vh;
  `
)

export default function App() {
  const { toast, openToast, closeToast } = useToast()
  return (
    <Background>
      <Container>
        <ScreenSelector openToast={openToast} />
        <Toast toast={toast} closeToast={closeToast} />
      </Container>
    </Background>
  )
}

import React from 'react'
import { Avatar, Typography } from '@mui/material'

import styled from '@emotion/styled'

const DiscordAvatar = styled(Avatar)`
  width: 80px;
  height: 80px;
  border-color: #a82b73;
  border-width: 5px;
  border-style: solid;
  text-align: center;
  margin: 10px auto;
`

export const DiscordUser: React.FC<{
  username?: string | null
  avatarUrl: string | null
}> = ({ username, avatarUrl }) => {
  return (
    <>
      <DiscordAvatar alt={username || undefined} src={avatarUrl || undefined} />
      {username && (
        <Typography variant="h3" mb={5}>
          {username}
        </Typography>
      )}
    </>
  )
}

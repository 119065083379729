/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
//import { styled, Typography } from '@mui/material'
import { useWallet } from '../hooks/useWallet'
import { ConnectWallet } from './ConnectWallet'
//import { formatAccount } from '../utils/web3'
import { ReactComponent as HalSVG } from '../assets/images/hal.svg'
import { DiscordUser } from './DiscordUser'
import ScreenContainer from './ScreenContainer'
import { TitleAndText } from './TitleAndText'

import { ToastMethodType } from '../hooks/useToast'
import Button from './button/Button'
import { login, useOAuth } from '../hooks/useOAuth'
import discordLogoSrc from '../assets/images/discord-logo.png'
import { AIFATextField } from './textfield/AIFATextField'
import { apiPost } from '../utils/api'
import {
  SignUpResultBody,
  RegisterWalletAddressResponse,
  //ApiResultType,
} from 'api/src'
import { MailchimpSignUp } from './MailchimpSignUp'
import styled from '@emotion/styled'
import { Typography } from '@mui/material'

const HALEye = styled(HalSVG)`
  max-width: 140px;
  height: auto;
  margin-bottom: 32px;
`

//Avoids build issue - TODO, use import
enum ApiResultType {
  Success = 'success',
  Error = 'error',
}

export const ScreenSelector: React.FC<{
  openToast: ToastMethodType
}> = ({ openToast }) => {
  const loginState = useOAuth()
  const [signupState, setSignupState] = useState<
    | SignUpResultBody
    | { result: ApiResultType.Error; error: string }
    | undefined
  >()
  const { account } = useWallet(openToast)
  const [walletAddress, setWalletAddress] = useState<string | undefined>()
  const [registerAddress, setRegisterAddress] = useState<
    | RegisterWalletAddressResponse
    | { result: ApiResultType.Error; error: string }
    | undefined
  >()

  //TODO: This code needs a refactor
  useEffect(() => {
    if (loginState.type === 'Invalid') {
      openToast({ severity: 'error', message: 'Invalid login' })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginState.type])

  useEffect(() => {
    if (
      signupState?.result === ApiResultType.Error &&
      signupState?.error !== 'UserNotGuildMember'
    ) {
      openToast({
        severity: 'error',
        message: signupState.error,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(signupState)])

  useEffect(() => {
    if (
      registerAddress?.result === ApiResultType.Error &&
      ![
        'WalletAddressAlreadyExists',
        'NotInSnapshot',
        'WhitelistRegistrationEnded',
      ].includes(registerAddress.error)
    ) {
      openToast({
        severity: 'error',
        message: registerAddress.error,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(registerAddress)])

  useEffect(() => {
    const fetchData = async () => {
      if (loginState.accessToken) {
        try {
          setSignupState(
            await apiPost('signup', loginState.accessToken, undefined)
          )
        } catch (err) {
          setSignupState({
            result: ApiResultType.Error,
            error: JSON.stringify(err),
          })
        }
      }
    }
    fetchData()
  }, [loginState.accessToken])

  useEffect(() => {
    const fetchData = async () => {
      if (
        loginState.accessToken &&
        (account || walletAddress) &&
        signupState?.result === ApiResultType.Success
      ) {
        try {
          setRegisterAddress(
            await apiPost('register-address', signupState.jwtToken, {
              walletAddress: account || walletAddress,
            })
          )
        } catch (err) {
          setSignupState({
            result: ApiResultType.Error,
            error: JSON.stringify(err),
          })
        }
      }
    }
    fetchData()
  }, [
    loginState.accessToken,
    walletAddress,
    account,
    JSON.stringify(signupState),
  ])

  if (loginState.type === 'Guest' || loginState.type === 'Invalid') {
    return (
      <ScreenContainer showField>
        <TitleAndText
          title="Welcome"
          text="Login with your Discord account to register, or to verify if you are on the whitelist."
        />
        <Button variant="contained" onClick={login}>
          <img
            src={discordLogoSrc}
            alt={'Discord'}
            style={{ height: '20px', marginRight: '10px' }}
          />
          Login to Discord
        </Button>
      </ScreenContainer>
    )
  } else if (
    signupState?.result === ApiResultType.Error &&
    signupState.error === 'UserNotGuildMember'
  ) {
    return (
      <ScreenContainer>
        <TitleAndText title="I'm Sorry" text="You are not a guild member." />
        <HALEye />
        <Typography variant="body1" align="center" margin={4}>
          Please join our{' '}
          <a href="https://discord.com/invite/alteredstatemachine">
            Discord server
          </a>{' '}
          for updates.
        </Typography>
      </ScreenContainer>
    )
  } else if (signupState?.result === ApiResultType.Success) {
    const { username, avatarUrl, isRegistrationActive } = signupState
    if (!isRegistrationActive) {
      if (!!signupState.walletAddress) {
        return (
          <ScreenContainer showField>
            <DiscordUser username={`${username}`} avatarUrl={avatarUrl} />
            <TitleAndText
              title="You are verified"
              text="You made it onto the whitelist. Prepare for total ASMilation."
            />
            <MailchimpSignUp openToast={openToast} />
          </ScreenContainer>
        )
      }
    }

    return (
      <ScreenContainer
        showField={
          !(
            registerAddress?.result === ApiResultType.Error &&
            registerAddress?.error === 'NotInSnapshot'
          )
        }
      >
        {registerAddress?.result === ApiResultType.Success ? (
          <>
            <DiscordUser username={`${username}`} avatarUrl={avatarUrl} />
            <TitleAndText
              title="You are verified"
              text="You made it onto the whitelist. Prepare for total ASMilation."
            />
            <MailchimpSignUp openToast={openToast} />
          </>
        ) : signupState?.isASMilated && !signupState?.walletAddress ? (
          <>
            <DiscordUser username={`Hello ${username}`} avatarUrl={avatarUrl} />
            {signupState.isRegistrationActive ? (
              <ConnectWallet openToast={openToast} />
            ) : registerAddress?.result === ApiResultType.Error &&
              registerAddress?.error === 'WhitelistRegistrationEnded' ? (
              <>
                <TitleAndText title="The Whitelist is now Closed!">
                  You're probably already with us on{' '}
                  <a href="https://discord.gg/creqnzuvEW">Discord</a>, or{' '}
                  <a href="https://twitter.com/altstatemachine">Twitter</a>, but
                  for even more ASM joy (and special secrets) sign up for our
                  email list below:
                </TitleAndText>
                <MailchimpSignUp openToast={openToast} />
              </>
            ) : walletAddress ? (
              <TitleAndText text="Checking your wallet address..." isLoading />
            ) : (
              <>
                <TitleAndText
                  title="Please verify"
                  text="Enter your wallet address to see if you are already on the whitelist."
                />
                <AIFATextField
                  type="text"
                  buttonLabel="Check"
                  label="Wallet Address"
                  onButtonPress={(address: string | undefined) =>
                    setWalletAddress(address)
                  }
                  disabled={!!walletAddress}
                  error={registerAddress?.result === ApiResultType.Error}
                  errorMessage={registerAddress?.error}
                />
                {walletAddress &&
                  registerAddress?.result !== ApiResultType.Error && (
                    <TitleAndText
                      text="Checking your wallet address..."
                      isLoading
                    />
                  )}
              </>
            )}
          </>
        ) : registerAddress?.result === ApiResultType.Error &&
          registerAddress?.error === 'WalletAddressAlreadyExists' ? (
          <>
            <DiscordUser username={`${username}`} avatarUrl={avatarUrl} />
            <TitleAndText
              title="You are verified"
              text="You made it onto the whitelist. Prepare for total ASMilation."
            />
            <MailchimpSignUp openToast={openToast} />
          </>
        ) : registerAddress?.result === ApiResultType.Error &&
          registerAddress?.error === 'NotInSnapshot' ? (
          <>
            <DiscordUser avatarUrl={avatarUrl} />

            <TitleAndText
              title={`I'm sorry, ${username}`}
              text="You are not on the whitelist."
            />

            <HALEye />
            <MailchimpSignUp
              openToast={openToast}
              headerText="Sign up for updates on the public sale"
            />
          </>
        ) : registerAddress?.result === ApiResultType.Error &&
          registerAddress?.error === 'WhitelistRegistrationEnded' ? (
          <>
            <TitleAndText title="The Whitelist is now Closed!">
              You're probably already with us on{' '}
              <a href="https://discord.gg/creqnzuvEW">Discord</a>, or{' '}
              <a href="https://twitter.com/altstatemachine">Twitter</a>, but for
              even more ASM joy (and special secrets) sign up for our email list
              below:
            </TitleAndText>
            <MailchimpSignUp openToast={openToast} />
          </>
        ) : (
          <>
            <DiscordUser username={`Hello ${username}`} avatarUrl={avatarUrl} />
            {signupState.walletAddress ? (
              <>
                <TitleAndText
                  title="You are verified"
                  text="You made it onto the whitelist. Prepare for total ASMilation."
                />
                <MailchimpSignUp openToast={openToast} />
              </>
            ) : (
              <>
                <TitleAndText
                  title="Please verify"
                  text="Enter your wallet address to see if you are already on the whitelist."
                />
                <AIFATextField
                  type="text"
                  buttonLabel="Check"
                  label="Wallet Address"
                  onButtonPress={(address: string | undefined) =>
                    setWalletAddress(address)
                  }
                  disabled={!!walletAddress}
                  error={registerAddress?.result === ApiResultType.Error}
                  errorMessage={registerAddress?.error}
                />
                {walletAddress &&
                  registerAddress?.result !== ApiResultType.Error && (
                    <TitleAndText
                      text="Checking your wallet address..."
                      isLoading
                    />
                  )}
              </>
            )}
          </>
        )}
      </ScreenContainer>
    )
  }
  return (
    <ScreenContainer showField>
      <TitleAndText text="Loading Discord user info..." isLoading />
    </ScreenContainer>
  )
}

export default ScreenSelector

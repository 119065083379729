import styled from '@emotion/styled'
import { Button as MuiButton, ButtonProps } from '@mui/material'

export type { ButtonProps } from '@mui/material'

const Span = styled.span`
  transform: skew(12deg, 0);
`

export const Button = <C extends React.ElementType>({
  to,
  children,
  ...props
}: ButtonProps<C, { component?: C, to?: string }>) => (
  <MuiButton {...props} to={to} role={to && 'link'}>
    <Span>{children}</Span>
  </MuiButton>
)

export default Button

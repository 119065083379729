export const apiPost = async (api: string, accessToken: string, body: any) => {
  const result = await fetch(`${process.env.REACT_APP_API_ROOT}${api}`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      pragma: 'no-cache',
      'cache-control': 'no-cache',
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify(body),
  })
  return await result.json()
}

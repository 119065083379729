import { Web3ReactProvider } from '@web3-react/core'
import { ThemeProvider as EmotionProvider, Theme } from '@emotion/react'
import { CssBaseline } from '@mui/material'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import { getLibrary } from './utils/web3'

import theme from './styles/theme'
import GlobalStyles from './styles/GlobalStyles'

const Providers: React.FC = ({ children }) => (
  <Web3ReactProvider getLibrary={getLibrary}>
    <ThemeProvider theme={theme}>
      <EmotionProvider theme={theme as Partial<Theme>}>
        <StyledEngineProvider injectFirst>
          <GlobalStyles />
          <CssBaseline />
          {children}
        </StyledEngineProvider>
      </EmotionProvider>
    </ThemeProvider>
  </Web3ReactProvider>
)

export default Providers

import { useCallback } from 'react'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector'
import {
  WalletConnectConnector,
  UserRejectedRequestError as UserRejectedRequestErrorWC,
} from '@web3-react/walletconnect-connector'
import { useWeb3React } from '@web3-react/core'

import {
  connectors,
  ConnectorNames,
  setConnectorToStorage,
  removeConnectorFromStorage,
} from '../utils/web3'
import { ToastMethodType } from './useToast'

export const useWallet = (openToast: ToastMethodType) => {
  const { activate, deactivate, ...rest } = useWeb3React()

  const connectWallet = useCallback(
    (connectorName: ConnectorNames) => {
      setConnectorToStorage(connectorName)

      const connector = connectors[connectorName]

      if (connector) {
        activate(connector, async (error: Error) => {
          removeConnectorFromStorage()

          if (error instanceof NoEthereumProviderError) {
            openToast({ message: 'No provider was found', severity: 'error' })
          } else if (
            error instanceof UserRejectedRequestErrorWC ||
            error instanceof UserRejectedRequestErrorInjected ||
            // For wallet-link connector
            error.message === 'User denied account authorization'
          ) {
            if (connector instanceof WalletConnectConnector) {
              const walletConnector = connector as WalletConnectConnector
              walletConnector.walletConnectProvider = null
            }
            openToast({
              severity: 'error',
              message: 'Please authorise to connect wallet',
            })
          } else if (error.message.indexOf('Unsupported chain id') !== -1) {
            openToast({
              message:
                'Sorry, human error, please change wallet to Ethereum Mainnet.',
              severity: 'error',
            })
          } else {
            openToast({ message: error.message, severity: 'error' })
          }
        })
      } else {
        openToast({ message: 'Unable to find connector', severity: 'error' })
      }
    },
    [activate, openToast]
  )

  const disconnectWallet = useCallback(() => {
    deactivate()

    // This key is set by @web3-react/walletconnect-connector
    if (localStorage.getItem('walletconnect')) {
      const walletconnect = connectors[
        ConnectorNames.WalletConnect
      ] as WalletConnectConnector

      walletconnect.close()
      walletconnect.walletConnectProvider = null
    }

    removeConnectorFromStorage()
  }, [deactivate])

  return { connectWallet, disconnectWallet, ...rest }
}

import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { Alert, Snackbar, useMediaQuery } from '@mui/material'
import { ToastMethodType, ToastType } from '../../hooks/useToast'

const StyledAlert = styled(Alert)(
  ({ theme, severity }) => css`
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    background-color: ${severity === 'error'
      ? theme.palette.error.main
      : theme.palette.success.main};

    max-width: 1000px;

    .MuiAlert-icon {
      color: #fff;
    }

    ${theme.breakpoints.down('sm')} {
      max-width: 95%;
    }
  `
)

const Toast: React.FC<{ toast: ToastType; closeToast: ToastMethodType }> = ({
  toast,
  closeToast,
}) => {
  const { open, message, severity } = toast

  const handleClose = () => {
    closeToast({ message, severity })
  }

  const isTabletOrMobile = useMediaQuery('(max-width: 800px)')

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{
        vertical: isTabletOrMobile ? 'top' : 'bottom',
        horizontal: 'center',
      }}
    >
      <StyledAlert onClose={handleClose} severity={severity}>
        {message}
      </StyledAlert>
    </Snackbar>
  )
}

export default Toast

import styled from '@emotion/styled'
import { Button, TextField, Typography } from '@mui/material'
import { useEffect, useRef } from 'react'
import MailchimpSubscribe, {
  FormHooks,
  EmailFormFields,
} from 'react-mailchimp-subscribe'
import { ToastMethodType } from '../hooks/useToast'

const EmailField = styled(TextField)`
  margin-right: -22px;
  input {
    height: 21px;
    background-color: white;
  }
  max-width: 100%;
  @media (min-width: 600px) {
    width: 400px;
  }
  @media (min-width: 400px) and (max-width: 600px) {
    width: 300px;
  }
  @media (max-width: 600px) {
    input {
      height: 13px;
    }
  }
  label {
    color: black;
    font-size: 1.2rem;
    &[data-shrink='true'] {
      margin-top: -4px;
    }
  }
  p a {
    margin-top: 10px;
    text-decoration: underline;
  }
`

const MailchimpForm: React.FC<
  {
    openToast: ToastMethodType
    headerText?: string
  } & FormHooks<EmailFormFields>
> = ({ openToast, subscribe, status, message, headerText }) => {
  const emailInputRef = useRef<HTMLInputElement>()
  useEffect(() => {
    if (status === 'success') {
      openToast({
        message: message as string,
        severity: status,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  if (status === 'success') {
    return null
  }

  const disabled = status === 'sending'
  return (
    <>
      <Typography variant="h4" m={4} mb={3}>
        {headerText || 'Sign up for updates'}
      </Typography>
      <div style={{ marginBottom: 20 }}>
        <EmailField
          id="email-field"
          name="email"
          variant="filled"
          label="Enter your email"
          type="email"
          error={status === 'error'}
          inputRef={emailInputRef}
          helperText={
            //Purpose: sometimes the response has html
            message && (
              <div dangerouslySetInnerHTML={{ __html: message as string }} />
            )
          }
          disabled={disabled}
        />
        <Button
          variant="contained"
          disabled={disabled}
          onClick={() =>
            subscribe({ EMAIL: emailInputRef?.current?.value as string })
          }
        >
          Join
        </Button>
      </div>
    </>
  )
}

export const MailchimpSignUp: React.FC<{
  openToast: ToastMethodType
  headerText?: string
}> = (parentProps) => (
  <MailchimpSubscribe
    url={
      'https://xyz.us6.list-manage.com/subscribe/post?u=5f854bc1dbec9760088df34eb&id=935ecf44c9'
    }
    render={(props) => <MailchimpForm {...parentProps} {...props} />}
  />
)

import React from 'react'

import styled from '@emotion/styled'

import { ReactComponent as AIFALogo } from '../assets/images/aifa-logo.svg'

import { ReactComponent as PurpleBall } from '../assets/images/purple-ball.svg'
import { ReactComponent as FieldLines } from '../assets/images/field-lines.svg'
import bouncingBall from '../assets/images/bouncing-ball.png' // with import

const Header = styled.div`
  background-color: #186b82;
  text-align: center;
  padding: 20px;
  padding-bottom: 15px;
  border-radius: 20px 20px 0 0;
  margin-top: 10px;
`

const Body = styled.div`
  background-color: #c0effc;
  text-align: center;
  border-radius: 0 0 20px 20px;
  position: relative;
  overflow: hidden;
  margin-bottom: 10px;
`

const BodyInner = styled.div`
  padding: 40px;
  padding-top: 20px;
  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (min-width: 900px) {
    min-height: 445px;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const Logo = styled(AIFALogo)`
  height: 40px;
`

const Field = styled(FieldLines)`
  margin-left: auto;
  margin-right: 0;
  margin-bottom: -20px;
  margin-top: -70px;
`

const FootballOuter = styled.div`
  direction: rtl;
`

const BouncingFootball = styled.img`
  width: 100px;
  position: absolute;
  right: 100px;
  bottom: 48px;
`

const FootballShadow = styled(PurpleBall)`
  width: 100px;
  position: absolute;
  right: 100px;
  bottom: 50px;
`

export const ScreenContainer: React.FC<{
  showField?: boolean
}> = ({ showField, children }) => {
  return (
    <>
      <Header>
        <Logo />
      </Header>
      <Body>
        <BodyInner>{children}</BodyInner>
        {showField && (
          <>
            <BouncingFootball src={bouncingBall} alt="x" />
            <FootballShadow />
            <FootballOuter>
              <Field />
            </FootballOuter>
          </>
        )}
      </Body>
    </>
  )
}

export default ScreenContainer

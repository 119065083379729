import { Web3Provider } from '@ethersproject/providers'
import { AbstractConnector } from '@web3-react/abstract-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'

export const POLLING_INTERVAL = 12000

const CONNECTOR_STORAGE_KEY = 'connector'

export const getConnectorName = () => {
  const connector = localStorage.getItem(CONNECTOR_STORAGE_KEY)

  return connector ? (connector as ConnectorNames) : null
}

export const setConnectorToStorage = (connectorName: ConnectorNames) => {
  localStorage.setItem(CONNECTOR_STORAGE_KEY, connectorName)
}

export const removeConnectorFromStorage = () => {
  localStorage.removeItem(CONNECTOR_STORAGE_KEY)
}

export enum ConnectorNames {
  Coinbase = 'Coinbase',
  MetaMask = 'MetaMask',
  WalletConnect = 'WalletConnect',
}

type Connectors = Record<ConnectorNames, AbstractConnector>

// TODO: Need to confirm the chains with the client
export enum NetworkChainId {
  Mainnet = 1,
  //Ropsten = 3, // testnet
}

export const getLibrary = (provider: any): Web3Provider => {
  const library = new Web3Provider(provider)
  library.pollingInterval = POLLING_INTERVAL

  return library
}

// TODO: These are test keys for dev. Get proper keys and access these via env var
const RPC_URLS = {
  1: process.env.REACT_APP_RPC_URL!,
}

const networks = [NetworkChainId.Mainnet]

const metamask = new InjectedConnector({
  supportedChainIds: networks,
})

const walletconnect = new WalletConnectConnector({
  qrcode: true,
  pollingInterval: POLLING_INTERVAL,
  rpc: { 1: RPC_URLS[1] },
  bridge: 'https://bridge.walletconnect.org',
})

const coinbase = new WalletLinkConnector({
  url: RPC_URLS[1],
  appName: 'AIFA',
})

export const connectors: Connectors = {
  [ConnectorNames.MetaMask]: metamask,
  [ConnectorNames.Coinbase]: coinbase,
  [ConnectorNames.WalletConnect]: walletconnect,
}

export const formatAccount = (account: string | null | undefined) =>
  account
    ? `${account.substring(0, 6)}...${account.substring(account.length - 4)}`
    : ''

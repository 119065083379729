import styled from '@emotion/styled'
import { Button, TextField, TextFieldProps, Typography } from '@mui/material'
import { useRef } from 'react'

const FormTextField = styled(TextField)`
  margin-right: -22px;
  input {
    height: 21px;
    background-color: white;
  }
  max-width: 100%;
  @media (min-width: 600px) {
    width: 400px;
  }
  @media (min-width: 400px) and (max-width: 600px) {
    width: 300px;
  }
  @media (max-width: 600px) {
    input {
      height: 13px;
    }
  }
  label {
    color: black;
    font-size: 1.2rem;
    &[data-shrink='true'] {
      margin-top: -4px;
    }
  }
  p a {
    margin-top: 10px;
    text-decoration: underline;
  }
`

export const AIFATextField: React.FC<
  {
    title?: string
    errorMessage?: string
    buttonLabel?: string
    onButtonPress?: (inputValue?: string) => void
  } & TextFieldProps
> = ({ title, errorMessage, buttonLabel, onButtonPress, ...props }) => {
  const inputRef = useRef<HTMLInputElement>()
  return (
    <>
      {title && (
        <Typography variant="h4" mb={1}>
          {title}
        </Typography>
      )}
      <div>
        <FormTextField
          {...props}
          variant="filled"
          inputRef={inputRef}
          helperText={
            //Purpose: sometimes the response has html
            errorMessage && (
              <div
                dangerouslySetInnerHTML={{ __html: errorMessage as string }}
              />
            )
          }
        />
        {onButtonPress && (
          <Button
            variant="contained"
            disabled={props.disabled}
            onClick={() => onButtonPress(inputRef?.current?.value)}
          >
            {buttonLabel}
          </Button>
        )}
      </div>
    </>
  )
}

import { Box } from '@mui/material'
import { useWallet } from '../hooks/useWallet'

import { connectors, ConnectorNames } from '../utils/web3'

import coinbaseSrc from '../assets/images/coinbase-logo.png'
import metamaskSrc from '../assets/images/metamask-logo.png'
import walletConnectSrc from '../assets/images/walletconnect-logo.png'

import Button from './button/Button'
import styled from '@emotion/styled'
import { TitleAndText } from './TitleAndText'
import { ToastMethodType } from '../hooks/useToast'

const WalletContainer = styled(Box)`
  @media (max-width: 900px) {
    flex-direction: column;
    button {
      margin-bottom: 20px;
    }
  }
`

const StyledButtonLink = styled(Button)`
  margin: 0px 15px;
  @media (hover: hover) {
    :hover {
      color: #fff;
    }
  }
`

const connectorImageMap = {
  [ConnectorNames.Coinbase]: coinbaseSrc,
  [ConnectorNames.MetaMask]: metamaskSrc,
  [ConnectorNames.WalletConnect]: walletConnectSrc,
}

export const ConnectWallet: React.FC<{ openToast: ToastMethodType }> = ({
  openToast,
}) => {
  const { connectWallet } = useWallet(openToast)
  const generateConnectWalletHandler =
    (connectorName: ConnectorNames) => () => {
      connectWallet(connectorName)
    }
  return (
    <>
      <TitleAndText
        title="Connect your wallet"
        text="So we can register your Ethereum address on our whitelist."
      />
      <WalletContainer
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {Object.keys(connectors).map((key) => (
          <StyledButtonLink
            key={key}
            onClick={generateConnectWalletHandler(key as ConnectorNames)}
            variant="contained"
          >
            <img
              src={connectorImageMap[key as ConnectorNames]}
              alt={`${key} logo`}
              style={{ height: '20px', marginRight: '10px' }}
            />
            {key}
          </StyledButtonLink>
        ))}
      </WalletContainer>
    </>
  )
}
